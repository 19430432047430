@import "_html5boilerplate";
@import "_magnificPopup.0.9.3";
@import "_globals";
@import "_utilities";
@import "produkt/kategorija";

$body-text-color: #575757;


/*Ext reset*/
html, body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form, fieldset, input, p, blockquote, th, td {
  margin: 0;
  padding: 0;
}

img, body, html {
  border: 0;
}

address, caption, cite, code, dfn, em, strong, th, var {
  font-style: normal;
  font-weight: normal;
}

ol, ul {
  list-style: none;
}

caption, th {
  text-align: left;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
}

q:before, q:after {
  content: '';
}

STRONG {
  font-weight: bold;
}

EM {
  font-style: italic;
}

@import 'referenceProjects';

/** Infotip ***/
A.infotip {
  cursor: pointer;
  /*+placement:shift 0px -1px;*/
  position: relative;
  left: 0px;
  top: -1px;
  margin-left: 2px;
  margin-right: 0px;
}

.prdktResetkeNaZalogi_narocilo .infotip {
  top: -2px;
}

.tooltip {
  display: none;
  /*[disabled]display:block;*/
  background-image: url(/static/img/bubble_desno_top.png);
  background-repeat: no-repeat;
  padding: 58px 30px 0px 45px;
  width: 205px;
  font-size: 11px;
  color: #FFF;
  z-index: 10550;
  text-align: left;
  line-height: 1.6em;
  min-height: 75px;
  font-weight: normal;
}

SPAN.tooltip IMG {
  border: 1px solid #B6B6B6;
  padding: 1px;
  margin: 5px auto;
}

.tooltip .bottom {
  width: 280px;
  height: 28px;
  background-image: url(/static/img/bubble_desno_bottom.png);
  position: absolute;
  bottom: -28px;
  left: 0px;
}

/*** Infotip END **

**  METALnet CSS  ***/

UL.list_vertical {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

H1, H2, H3, H4 {
  font-family: $fontFamilyStandard;
  font-weight: normal;
}

H1 {
  //color: $colorSecondary;
  color: $colorPrimary;
  font-size: 28px;
  font-family: $fontFamilyStandard;
  margin-top: 25px;
  margin-bottom: 18px;
  font-style: normal;
  font-weight: 500;
}

H2 {
  //font-weight: bold;
  
  color: $colorPrimary;
  font-size: 22px;
  margin: 20px 0 10px;
}

H3 {
  font-size: 17px;
  color: $colorPrimary;
  margin: 15px 0;
}

H4 {
  color: $colorPrimary;
  margin-bottom: 0;
}

INPUT.form_problem {
  background-color: #FFA19B;
  border-style: solid;
}

DIV.main-wrap {
  //width: 967px;
  width: $pageWidth;
  position: relative;
  margin: 0 auto;
  //padding-top: 1px;
}

DIV#vsebina {
  width: 604px;
  background: #FFFFFF;
  z-index: 50;
  border: 1px solid #DDDDDD;
  margin-top: 20px;
  margin-left: 173px;
  margin-bottom: 0;
  min-height: 1070px;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.2);
  position: relative;
  padding-right: 20px;
  padding: 24px 20px 36px 26px;
}

DIV#vsebina.full {
  width: 912px;
  margin-left: 4px;
}

DIV#vsebina.full_with_cart {
  margin-left: 4px;
  width: 739px;
}

DIV#vsebina UL > LI > UL {
  margin-top: 0;
}

.vsebina-wysiwyg {
  font-family: $fontFamilyStandard;
  
  H2 {
    font-size: 23px;
  }

  H3 {
    font-size: 18px;

    &.underline {
      border-bottom: 1px dotted #cccccc;
      margin-bottom: 4px;
    }
  }

  STRONG {
    font-weight: bold;
  }

  P {
    margin: 10px 0;
    line-height: 1.6;
    font-size: 14px;
    font-family: $fontFamilyStandard;
    color: $body-text-color;
  }

  UL > LI {
    margin: 3px 0 3px 25px;
    list-style: circle;
    padding-left: 0px;

    > UL > LI {
      padding-left: 0px;
    }
  }

  TABLE.x-btn {
    TD {
      background-color: transparent !important;
    }
  }

  TABLE {
    P {
      margin: 0 0;
    }

    TR {
      TH {
        font-weight: bold;
        color: #ffffff;
        background-color: #666666;
        vertical-align: middle;
        padding: 5px 6px;
      }

      TD {
        vertical-align: middle;
        padding: 3px 6px;
      }

      &:nth-child(odd) {

      }

      &:nth-child(even) {
        TD {
          background-color: #eeeeee;
        }
      }
    }
  }

  TABLE.border {
    border: 1px solid rgb(211, 211, 211);
    border-width: 1px 0 0 1px;

    TD {
      border: 1px solid rgb(211, 211, 211);
      border-width: 0 1px 1px 0;
    }

    TH {
      border: 1px solid rgb(140, 140, 140);
      border-width: 0 1px 1px 0;
    }
  }

  TABLE.first-col-small-padding {
    TR {
      TD:first-child, TH:first-child {
        padding: 0 1px;
      }
    }
  }
}

@import "_navigationSide";
@import "_navigationTop";

/***  Top area  ***/
.top-area {
  min-width: $pageWidth;
  height: 69px;
  left: 0;
  top: 0;
  background-color: $colorPrimary;

  &--front {
    background-color: transparent;
  }

  &__wrap {
    margin: 0 auto;
    width: $pageWidth;
    display: flex;
  }


  &__links {
    right: 0;
    padding-top: 27px;
    text-align: right;
    font-size: 11px;
    margin-left: auto;
    color: #FFFFFF;

    &--front {
      color: $colorLink;
    }

    A {
      margin-right: 8px;
      margin-left: 8px;
      text-decoration: none;
      color: inherit;

      &:hover {
        text-decoration: underline;
        color: inherit;
      }
    }

  }
}

.wysiwyg-content {
  P {
    margin: 10px 0;
  }
}

.mnlogo {
  display: block;
  left: 4px;
  top: 17px;
  background-color: #FFFFFF;
  width: 250px;
  padding: 17px;
}

DIV#search_box {
  /*+placement:anchor-top-right 3px 15px;*/
  position: absolute;
  right: 3px;
  top: 15px;
  background-image: url(/static/img/search.png);
  height: 36px;
  width: 231px;
}

DIV#search_box INPUT.search-txtbox {
  /*+placement:anchor-top-left 9px 9px;*/
  position: absolute;
  left: 9px;
  top: 9px;
  height: 18px;
  width: 172px;
  border: 0px solid #000000;
  padding-left: 7px;
  font-size: 12px;
  color: #8C8C8C;
}

DIV#search_box INPUT.search-txtbox.italic {
  font-style: italic;
}

DIV#search_box A {
  /*+placement:anchor-top-right 10px 8px;*/
  position: absolute;
  right: 10px;
  top: 8px;
  background-image: url(/static/img/search.png);
  background-position: -189px -8px;
  height: 20px;
  width: 33px;
  text-indent: -9999px;
  outline: none;
}

/***  Breadcrumbs  ***/
/*[clearfix]*/
DIV#breadcrumbs {
  /*+placement:anchor-top-left 0px 0px;*/
  position: absolute;
  left: 0px;
  top: 0px;
  font-size: 10px;
}

DIV#breadcrumbs DIV {
  float: left;
  height: 18px;
  position: relative;
  background-repeat: repeat-x;
  padding-right: 4px;
  padding-left: 18px;
  padding-top: 5px;
}

DIV#breadcrumbs DIV A {
  text-decoration: none;
  color: #424242;
}

DIV#breadcrumbs DIV A:hover {
  text-decoration: underline;
}

DIV#breadcrumbs DIV.bc_start {
  background-image: url(/static/img/breadcrumbs_left.png);
  width: 4px;
  height: 23px;
  padding-top: 0px;
  padding-left: 0px;
  padding-bottom: 0px;
  padding-right: 0px;
}

DIV#breadcrumbs DIV SPAN {
  display: inline-block;
  width: 13px;
  height: 23px;
  /*+placement:anchor-top-right -12px 0px;*/
  position: absolute;
  right: -12px;
  top: 0px;
  z-index: 1000;
}

DIV#breadcrumbs DIV.bc_home {
  background-image: url(/static/img/breadcrumbs_1_back.png);
  padding-left: 2px;
}

DIV#breadcrumbs DIV.bc_home SPAN {
  background-image: url(/static/img/breadcrumbs_1_end.png);
}

DIV#breadcrumbs DIV.bc_home A {
  text-indent: -9999px;
  display: block;
  width: 19px;
  height: 15px;
  background-repeat: no-repeat;
  background-image: url(/static/img/breadcrumbs_home.png);
  background-position: 3px -1px;
}

DIV#breadcrumbs DIV.bc_0 {
  background-image: url(/static/img/breadcrumbs_2_back.png);
}

DIV#breadcrumbs DIV.bc_0 SPAN {
  background-image: url(/static/img/breadcrumbs_2_end.png);
}

DIV#breadcrumbs DIV.bc_1 {
  background-image: url(/static/img/breadcrumbs_3_back.png);
}

DIV#breadcrumbs DIV.bc_1 SPAN {
  background-image: url(/static/img/breadcrumbs_3_end.png);
}

DIV#breadcrumbs DIV.bc_2 {
  background-image: url(/static/img/breadcrumbs_4_back.png);
}

DIV#breadcrumbs DIV.bc_2 SPAN {
  background-image: url(/static/img/breadcrumbs_4_end.png);
}

/*** Side portleti ***/
DIV.stranski-portleti-cont {
  /*+placement:anchor-top-right 0px 309px;*/
  position: absolute;
  right: 0;
  top: 240px;
}

DIV#stranski-portleti {
  width: 177px;
  position: relative;
}

/***  Naročilnica  ***/
DIV#narocilnica {
  border: 1px solid #DDDDDD;
  -webkit-border-radius: 0 8px 8px 0;
  -moz-border-radius: 0 8px 8px 0;
  border-radius: 0 8px 8px 0;
  width: 159px;
  position: relative;
  overflow: hidden;
  padding-left: 13px;
  background-color: #FFFFFF;
  background-image: url(/static/img/side_menu_back.jpg);
  padding-top: 40px;
  min-height: 36px;
  z-index: 0;
  padding-bottom: 17px;
  vertical-align: 160px;
}

DIV#narocilnica UL {
  padding-left: 0px;
  padding: 0px;
  margin: 0px;
  list-style: none;
}

DIV#narocilnica UL LI {
  padding-top: 1px;
  margin-bottom: 5px;
}

DIV#narocilnica H4 {
  padding: 5px 0 0 15px;
  -webkit-border-radius: 0 8px 0 0;
  -moz-border-radius: 0 8px 0 0;
  border-radius: 0 8px 0 0;
  margin: -1px;
  background-image: url(/static/img/narocilnica_header_back.jpg);
  font-family: $fontFamilyStandard;
  color: #FFFFFF;
  font-size: 19px;
  /*+placement:anchor-top-left 0px 0px;*/
  position: absolute;
  left: 0px;
  top: 0px;
  height: 30px;
  display: block;
  width: 159px;
  text-shadow: 1px 1px #222222;
  font-weight: normal;
}

BODY.hr DIV#narocilnica H4 {
  padding-top: 7px;
  font-size: 17px;
}

DIV#narocilnica .narocilnica_skupaj {
  margin-top: 12px;
  margin-bottom: 9px;
  color: #444444;
  font-weight: bold;
}

DIV#narocilnica .narocilnica_skupaj STRONG {
  font-size: 14px;
  color: #11598C;
}

DIV#narocilnica DIV.cart_icon {
  background-image: url("/static/img/sprite_icons_png.png");
  background-repeat: no-repeat;
  background-position: -1px -12px;
  width: 36px;
  height: 27px;
  /*+placement:anchor-top-right 8px 5px;*/
  position: absolute;
  right: 8px;
  top: 5px;
}

DIV#narocilnica_list {
  width: 140px;
}

DIV#narocilnica_list H5 {
  font-family: $fontFamilyStandard;
  font-size: 12px;
  color: #1F6492;
  margin: 10px 0px 4px;
  line-height: 18px;
  font-weight: bold;
}

DIV#narocilnica_list .opis {
  padding-left: 10px;
  font-size: 10px;
}

DIV#narocilnica_list .opis_line_strong {
  font-weight: bold;
  color: #555555;
}

DIV#narocilnica_list .opis_line_strong STRONG {
  color: #1F6492;
}

DIV#narocilnica_list H5 SPAN {
  color: #6A6A6A;
}

DIV #narocilnica .cart_title {
  margin-top: 10px;
  margin-bottom: 4px;
  font-family: $fontFamilyStandard;
  font-weight: bold;
  font-size: 12px;
  color: #333333;
}

.empty_cart_instructions {
  color: #555555;
  margin-top: 6px;
  font-size: 11px;
}

DIV#narocilnica .separator {
  background-image: url(/static/img/crta_narocilnica.png);
  background-repeat: repeat-x;
  height: 2px;
  margin-left: -12px;
  width: 170px;
  margin-top: 10px;
}

DIV#narocilnica {
  A.v_kosarico_link {
    $linkColor: #739900;
    display: inline-block;
    background-color: $linkColor;
    margin: 7px 0 5px;
    padding: 8px 17px 8px 17px;
    font-weight: 600;
    color: #FFFFFF;
    text-decoration: none;
    text-align: center;
    border-radius: 4px;
    &:hover {
      background-color: lighten($linkColor, 2%);
    }
  }
}

.edit-cart-link {
  text-align: center;
  display: block;
  font-size: 11px;
  color: #666666;
}

.side-cart-vat-notice {
  font-size:11px;
  margin-top:12px;
  color: #666666;
  text-align: center;
}

DIV#narocilnica .delivery-costs {
  margin-top: 10px;
  font-weight: bold;
}

DIV#narocilnica .odstrani_link {
  background-image: url(/static/img/narocilnica_odstrani.png);
  background-repeat: no-repeat;
  background-position: 0 1px;
  color: #444444;
  padding-left: 16px;
  font-size: 9px;
}

DIV#narocilnica DIV.cart_last_entries {
  /*background-color: #DDDDDD;*/
  color: #333333;
  margin-top: 18px;
  margin-bottom: 12px;
}

.narocilnica_cena_skupaj {
  font-weight: bold;
  font-size: 12px;
  /*color: #1F6492;*/
}

/***Side icons***/
UL#side_icons {
  width: 165px;
  position: relative;
  letter-spacing: -1px;
  font-family: $fontFamilyStandard;
  list-style: none;
  font-size: 14px;
  color: #858585;
  padding-right: 0px;
  margin-top: 30px;
  padding-left: 4px;
}

UL#side_icons LI {
  margin-bottom: 17px;
  position: relative;
  /*[disabled]padding-left:51px;*/
}

UL#side_icons LI.dostava .ikona {
  background-image: url(/static/img/side_ikone.jpg);
  /*+placement:anchor-top-left -6px 0px;*/
  position: absolute;
  left: -6px;
  top: 0px;
  width: 56px;
  height: 64px;
}

UL#side_icons LI.dostava STRONG {
  color: #5093E4;
  font-weight: bold;
}

UL#side_icons LI.zaloga .ikona {
  background-image: url(/static/img/side_ikone.jpg);
  background-position: 0px -71px;
  /*+placement:anchor-top-left -7px 10px;*/
  position: absolute;
  left: -7px;
  top: 10px;
  width: 56px;
  height: 40px;
}

UL#side_icons LI.zaloga STRONG {
  color: #D39E1A;
}

UL#side_icons LI.enkos .ikona {
  background-image: url(/static/img/side_ikone.jpg);
  background-position: 0px -110px;
  /*+placement:anchor-top-left -5px 3px;*/
  position: absolute;
  left: -5px;
  top: 3px;
  width: 56px;
  height: 52px;
}

UL#side_icons LI.enkos STRONG {
  color: #7AAE4C;
}

UL#side_icons .cais-logo {
  border: 1px solid #eaeaea;
  background-color: #fbfbfb;
  border-radius: 10px;
  display: inline-block;
  margin-top: 20px;
  margin-left: 30px;
  padding: 20px;
  text-align: center;
}

/*** Footer ***/
DIV.footer_wrap {
  background-color: #444444;
  /*border: 1px dashed #E6E6E6;
  border-width: 1px 0px 1px 0;*/
  margin-top: -28px;
  padding-top: 54px;
  min-width: $pageWidth;
}

DIV.footer {
  margin: 0px auto;
  width: 800px;
  padding-top: 0px;
  padding-bottom: 22px;
  padding-left: 175px;
  color: #F5F5F5;
}

DIV.footer A {
}

DIV.footer_group {
  font-weight: bold;
  font-size: 13px;
  color: #FFFFFF;
  margin-bottom: 14px;
}

DIV.footer > DIV.group {
  float: left;
  margin: 0px;
  padding: 0px;
  font-size: 11px;
  width: 195px;
}

DIV.footer > DIV.group_0 {
  width: 180px;

  BODY.de_at & {
    width: 220px;
  }
}

DIV.footer > DIV.group_1 {
  width: 238px;

  BODY.de_at & {
    width: 223px;
  }
}

DIV.footer > DIV > UL {
  list-style: none;
  padding: 0px 0px 0px 0;
  margin: 0px;
}

DIV.footer > DIV > UL > LI {
  margin-bottom: 7px;
  text-decoration: none;
  background-image: url(/static/img/footer_bullet.png);
  background-repeat: no-repeat;
  background-position: 3px 3px;
  padding-left: 13px;
  font-size: 11px;
}

DIV.footer A {
  text-decoration: none;
  color: #F5F5F5;
}

DIV.footer A:hover {
  color: #FFFFFF;
}

DIV.footnote {
  clear: both;
  text-align: center;
  font-size: 9px;
  color: #F5F5F5;
  padding-top: 9px;
  padding-bottom: 9px;
}

DIV.footer_separator {
  background-image: url(/static/img/crta_footer.png);
  background-repeat: repeat-x;
  height: 2px;
  width: 614px;
  margin: 0 auto;
}

DIV.footer H3 {
  color: #FFFFFF;
  margin-top: 0px;
  margin-bottom: 9px;
}

DIV.footer .naslov {
  margin-bottom: 17px;
  padding-left: 23px;
  background-image: url(/static/img/ikona_map_bright_gray.png);
  background-repeat: no-repeat;
  background-position: 0 1px;
}

DIV.footer .tel {
  margin-bottom: 17px;
  padding-left: 23px;
  background-image: url(/static/img/ikona_phone_bright_gray.png);
  background-repeat: no-repeat;
  background-position: 0 0px;
}

DIV.footer .email {
  padding-left: 23px;
  margin-bottom: 3px;
  background-image: url(/static/img/ikona_mail_bright_gray.png);
  background-repeat: no-repeat;
  background-position: 0 1px;
}

DIV.footer .email A {
  text-decoration: underline;
}

DIV.footer .kontakt {
  width: 242px;
  color: #F5F5F5;
}

/** Product tabs ***/
UL.product-tabs {
  /* The tabs will be rendered by JS */
  display: none;
  list-style: none;
  padding-left: 0px;
}

UL.product-tabs > LI {
  margin-top: 20px;
  margin-bottom: 26px;
}

UL.product-tabs > LI > A.tab_label {
  height: 18px;
  display: block;
  text-decoration: none;
  font-size: 14px;
  outline: none;
  font-weight: bold;
  border-bottom: 1px solid #E0E0E0;
  padding-bottom: 0px;
  margin-bottom: 6px;
  width: 555px;
}

/*** Product tabs jQuery ***/
/*[clearfix]*/
UL.js-tabs-labels {
  list-style: none;
  padding-left: 0px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  height: 28px;
  margin-top: 4px;
  position: relative;
}

UL.js-tabs-labels > LI {
  list-style: none;
  padding: 6px 30px 1px 32px;
  float: left;
  text-decoration: none;
  font-weight: bold;
  border: solid #E6E6E6;
  border-width: 1px 1px 0;
  margin: 0 4px 0 0;
  height: 20px;
  font-size: 12px;
  cursor: pointer;
  position: relative;

  background-color: #FFFFFF;
  //@include background(linear-gradient(top, #ffffff 39%, #f9f9f9 100%)); DEPRECATED - was a compass function
  background: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(39%, #ffffff), color-stop(100%, #f9f9f9));
  background: -moz-linear-gradient(top, #ffffff 39%, #f9f9f9 100%);
  background: -webkit-linear-gradient(top, #ffffff 39%, #f9f9f9 100%);
  background: linear-gradient(to bottom, #ffffff 39%, #f9f9f9 100%);
}

UL.js-tabs-labels > LI.active {
  background-color: #FEFEFE;
  background-image: none;
}

UL.js-tabs-labels > LI .icon {
  background-image: url(/static/img/tabs_slices.png);
  width: 22px;
  height: 17px;
  background-repeat: no-repeat;
  position: absolute;
  left: 3px;
  top: 3px;
}

UL.js-tabs-labels > LI.cenik {
  padding-left: 8px;
  margin-left: 34px;
}

UL.js-tabs-labels > LI.cenik.active {
  padding-left: 42px;
  margin-left: 0px;
}

UL.js-tabs-labels > LI.cenik .icon {
  background-position: 0 0px;
  background-image: url(/static/img/tabs_cenik.png);
  top: -1px;
  left: -35px;
  width: 35px;
  height: 29px;
}

UL.js-tabs-labels.opened > LI .icon {
  background-image: url(/static/img/tabs_slices.png);
  width: 22px;
  height: 17px;
  left: 4px;
  top: 5px;
}

UL.js-tabs-labels.opened > LI.cenik {
  margin-left: 0px;
  padding-left: 42px;
}

UL.js-tabs-labels > LI.cenik.active .icon {
  background-image: url(/static/img/tabs_slices.png);
  background-position: 0 -20px;
  width: 22px;
  height: 17px;
  left: 4px;
  top: 5px;
}

UL.js-tabs-labels > LI.no-icon .icon {
  display: none;
}

UL.js-tabs-labels > LI.no-icon {
  padding-left: 12px;
}

UL.js-tabs-labels > LI.galerija .icon {
  background-position: -23px -3px;
  top: 7px;
  left: 5px;
}

UL.js-tabs-labels > LI.galerija.active .icon {
  background-position: -23px -22px;
}

UL.js-tabs-labels > LI.nosilnost_teza .icon {
  background-position: -74px 1px;
  width: 17px;
  left: 6px;
  top: 4px;
}

UL.js-tabs-labels > LI.nosilnost_teza.active .icon {
  background-position: -74px -18px;
}

UL.js-tabs-labels > LI.skica .icon {
  background-image: url(/static/img/tabs_skica.png);
  background-position: 0px 3px;
  width: 17px;
  left: 6px;
  top: 4px;
}

UL.js-tabs-labels > LI.skica.active .icon {
  background-position: 0px -17px;
}

UL.js-tabs-labels > LI.dodatne_moznosti .icon {
  background-position: -45px -1px;
  top: 5px;
  left: 6px;
  height: 17px;
  width: 17px;
}

UL.js-tabs-labels > LI.dodatne_moznosti.active .icon {
  background-position: -45px -20px;
}

UL.js-tabs-labels > LI.icon {
  background-image: url(/static/img/tabs_slices.png);
  width: 10px;
  height: 10px;
  background-repeat: no-repeat;
  position: absolute;
}

UL.js-tabs-labels > LI.closed {
  border-width: 1px;
}

UL.js-tabs-labels > LI .toggle {
  background-image: url(/static/img/tabs_slices.png);
  width: 10px;
  height: 6px;
  background-repeat: no-repeat;
  position: absolute;
  right: 10px;
  top: 12px;
  background-position: -65px -2px;
}

UL.js-tabs-labels > LI.active .toggle {
  background-position: -65px -31px;
}

UL.js-tabs-labels > LI.active {
  background-color: #FFFFFF;
  height: 21px;
}

UL.js-tabs-labels > LI.active A {
  color: #11598C;
}

UL.js-tabs-labels > LI > A {
  text-decoration: none;
  outline: none;
  color: #333333;
}

UL.js-tabs-content {
  width: 600px;
  border: 1px solid #ECECEC;
  padding-left: 0px;
  list-style: none;
  margin-top: 0px;
  margin-bottom: 10px;
  //display: none;
}

UL.js-tabs-content > LI {
  display: none;
  padding: 16px 13px 10px 13px;
  margin: 0 0 0;
}

UL.js-tabs-content > LI.active {
  display: block;
}

UL.product-tabs-proc > LI {
  display: none;
  border: 1px solid #ECECEC;
  margin-top: 30px;
  margin-bottom: 9px;
}

UL.product-tabs-proc > LI.active {
  display: block;
}

UL.product-tabs-proc > LI > A.tab_label {
  /*+placement:float-left 0px 0px;*/
  float: left;
  position: relative;
  left: 0px;
  top: 0px;
}

UL.product-tabs-proc > LI.active > A.tab_label {
  background-color: #FFFFFF;
  /*[disabled]height:24px;*/
  z-index: 3000;
  /*+placement:float-left 0px 0px;*/
  float: left;
  position: relative;
  left: 0px;
  top: 0px;
  font-weight: bold;
  color: #11598C;
}

DIV.js-product-tabs DIV.tab_title {
  color: #11598C;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 5px;
}

/*** Produkt sklop ***/
DIV.produkt_sklop H2 {
  margin-bottom: 2px;
  font-size: 23px;
  margin-top: 24px;
  width: 460px;
}

/***Resetka opis***/
DIV.prdktResetkeNaZalogi_narocilo {
  margin-bottom: 20px;
}

DIV.resetka_opis {
  width: 383px;
  height: 95px;
  font-size: 11px;
  background-color: #FBFBFB;
  border: 1px solid #EFEFEF;
  padding-left: 178px;
  padding-top: 15px;
  position: relative;
  padding-bottom: 15px;
}

DIV.resetka_opis DIV.resetka_slika {
  /*+placement:anchor-top-left 0px 0px;*/
  position: absolute;
  left: 0px;
  top: 0px;
}

DIV.resetka_slika A.image_magnify {
  /*+placement:anchor-bottom-right 0px 0px;*/
  position: absolute;
  right: 0px;
  bottom: 0px;
  outline: none;
}

.plocevina_opis A.image_magnify {
  /*+placement:anchor-bottom-right 0px 0px;*/
  position: absolute;
  right: 0px;
  bottom: 0px;
  right: 0;
  bottom: 56px;
  outline: none;
  top: 10px;
}

.plocevina_opis .front_image {
  margin-left: 116px;
}

.plocevina_opis A.pdf_link {
  /*+placement:anchor-bottom-right 0px 0px;*/
  position: absolute;
  right: 0px;
  bottom: 0px;
  right: 0;
  top: 35px;
  outline: none;
}

/*** Perforirana pločevina opis ***/
DIV.plocevina_opis {
  width: 447px;
  height: 88px;
  background-color: #FBFBFB;
  border: 1px solid #EFEFEF;
  padding-left: 153px;
  padding-top: 20px;
  position: relative;
  padding-bottom: 15px;
}

DIV.plocevina_opis .slika {
  width: 100%;
  height: 123px;
  /*+placement:anchor-top-left 0px 0px;*/
  position: absolute;
  left: 0px;
  top: 0px;
  overflow: hidden;
}

DIV.plocevina_opis .slika .skica {
  /*+placement:anchor-top-left 0px 0px;*/
  position: absolute;
  left: 0px;
  top: 0px;
}

DIV.plocevina_opis .opis {
  /*+placement:anchor-bottom-left 0px 0px;*/
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 22px;
  background-color: rgba(255, 255, 255, 0.9);
}

DIV.plocevina_opis .lastnosti {
  padding-top: 0px;
  margin-top: 4px;
  margin-left: 9px;
}

DIV.plocevina_opis .lastnosti LI {
  float: left;
  margin-right: 25px;
  padding-top: 0px;
  margin-top: 0px;
}

DIV.plocevina_opis .lastnosti LI:last-child {
  margin-right: 0px;
}

/*** Tabela ***/
UL.tabela {
  list-style: none;
  padding-left: 0px;
  margin-top: 16px;
  border: 0px dotted #D2D2D2;
}

UL.tabela.topborder {
  border-width: 1px 0px 0px;
  margin-top: 15px !important;
}

/*[clearfix]*/
UL.tabela > LI {
  margin: 0;
  padding-bottom: 0px;
  height: 29px;
  padding-left: 9px !important;
  border-bottom: 1px dotted #D2D2D2;
  font-size: 12px;
}

UL.tabela > LI > DIV {
  float: left;
}

UL.tabela LI.no_header {
  margin-top: 12px;
  border-top: 1px dotted #D2D2D2;
}

UL.tabela LI.header_1 {
  margin-top: 12px;
  padding-top: 8px;
  height: 28px;
  color: #424242;
  font-size: 17px;
  padding-left: 0;
  font-weight: bold;
}

UL.tabela LI.header_1.resetke {
  background-color: #D9D9D9;
  border-top: 0px solid #000000;
  margin-top: 12px;
  padding-top: 8px;
  height: 23px;
  color: #333333;
  font-weight: normal;
  font-size: 12px;
  padding-left: 9px;
}

UL.tabela LI.header_1 > DIV {
  padding-top: 0px;
}

UL.tabela LI.header_2 {
  background-color: #E2E2E2;
  padding-top: 6px;
  height: 21px;
}

UL.tabela LI.header_2.single_header {
  margin-top: 12px;
}

UL.tabela LI.header_2 DIV {
  padding-top: 0px;
}

UL.tabela LI > DIV {
  float: left;
  padding-top: 7px;
}

/***Tabela rešetke***/
UL.tabela_resetke LI.header_2 DIV.kolicina {
  padding-top: 0px;
}

UL.tabela_resetke LI > DIV.sirina {
  width: 62px;
  /*text-align: right;*/
}

UL.tabela_resetke LI > DIV.dimenzijex {
  text-align: center;
  width: 42px;
}

UL.tabela_resetke LI > DIV.dolzina {
  width: 83px;
}

UL.tabela_resetke LI > DIV.cena_kos {
  width: 128px;
  font-weight: bold;
  text-align: right;
}

UL.tabela_resetke LI > DIV.cena_multiply {
  width: 41px;
  text-align: center;
}

UL.tabela_resetke LI > DIV.kolicina {
  width: 78px;
  padding-top: 2px;
}

UL.tabela_resetke LI > DIV.kolicina INPUT {
  width: 30px;
  margin-top: 2px;
}

UL.tabela_resetke LI > DIV.vrednost {
  width: 84px;
  font-weight: bold;
  text-align: center;
  padding-right: 12px;
}

UL.tabela_resetke LI > DIV.vkosarico {
  width: 28px;
  padding-top: 2px;
}


/*** Okrogle rešetke ***/
UL.tabela_okrogleResetke LI.header_2 DIV.kolicina {
  padding-top: 0px;
}

UL.tabela_okrogleResetke LI > DIV.premer {
  width: 85px;
  /*text-align: right;*/
}

UL.tabela_okrogleResetke LI > DIV.nosilni_trak {
  width: 98px;
  /*text-align: right;*/
}

UL.tabela_okrogleResetke LI > DIV.cena_kos {
  width: 80px;
  font-weight: bold;
  text-align: right;
}

UL.tabela_okrogleResetke LI > DIV.cena_multiply {
  width: 41px;
  text-align: center;
}

UL.tabela_okrogleResetke LI > DIV.kolicina {
  width: 78px;
  padding-top: 2px;
}

UL.tabela_okrogleResetke LI > DIV.kolicina INPUT {
  width: 30px;
  margin-top: 2px;
}

UL.tabela_okrogleResetke LI > DIV.vrednost {
  width: 84px;
  font-weight: bold;
  text-align: center;
  padding-right: 12px;
}

UL.tabela_okrogleResetke LI > DIV.vkosarico {
  width: 28px;
  padding-top: 2px;
}

/*** Perforirana pločevina tabela ***/
UL.tabela_perforirana LI {
  > .format {
    width: 166px;
  }

  > .debelina {
    width: 120px;
    text-align: center;
    padding-right: 5px;
  }

  > .quantity {
    width: 90px;
    text-align: left;
  }

  > .profil_material {
    width: 200px;
  }

  > .cena_kos {
    width: 108px;
    text-align: center;
  }

  > .cena_kos STRONG {
    /*[disabled]color:#11598C;*/
  }

  > .cena_skupaj {
    width: 150px;
    text-align: center;

    .st_kosov {
      color: #a0a0a0;
    }

    STRONG {
      /*[disabled]color:#11598C;*/
    }
  }

  > .vkosarico {
    width: 29px;
    padding-top: 2px;
  }
}

/*** Vpenjalni profili tabela ***/
UL.tabela_vpenjalni {
  LI > .material {
    width: 190px;
  }

  /*LI > .cena_kos {
    width: 125px;
    text-align: center;
  }*/
  LI > .quantity {
    width: 142px;
    font-size: 0.9em;
  }

  LI > .cena_skupaj {
    width: 165px;
    text-align: center;

    .st_kosov {
      color: #a0a0a0;
    }
  }

  LI > .vkosarico {
    width: 28px;
    padding-top: 2px;
  }
}

/*** Tabela kleme ***/
UL.tabela_kleme {
  LI > DIV.material {
    width: 220px;
  }

  LI > .cena_kos {
    //width: 132px;
  }

  LI > .cena_kos STRONG {
    /*[disabled]color:#11598C;*/
  }

  LI > .quantity {
    width: 110px;
  }

  LI > .cena_skupaj {
    width: 163px;
    text-align: center;
  }

  LI > .cena_skupaj STRONG {
    /*[disabled]color:#11598C;*/
  }

  LI > .vkosarico {
    width: 28px;
    padding-top: 2px;
  }
}


/***Lastnosti produkta***/
UL.lastnosti {
  list-style: none;
  margin-left: 0px;
  padding-left: 0px;
  /*[empty]padding-bottom:;*/
}

UL.lastnosti LI {
  padding-bottom: 3px;
}

UL.lastnosti SPAN.opis_lastnosti {
  font-weight: bold;
}

DIV.cene_brez_ddv {
  font-size: 11px;
  color: #777777;
  text-align: right;
  /*margin-top: -10px;*/
}

DIV.cene_brez_ddv.top {
  margin-top: 10px;
  margin-bottom: -13px;
}

DIV.cene_brez_ddv.top-with-title {
  margin-top: 20px;
  margin-bottom: -41px;
}

/***Dobava***/
DIV.dobava {
  /*+placement:anchor-top-right 0px -22px;*/
  position: absolute;
  right: 0px;
  top: -22px;
  /*background-image: url(/static/img/ikona_dobava.png);*/
  background-repeat: no-repeat;
  height: 20px;
  padding-left: 29px;
  padding-top: 7px;
  font-size: 11px;
}

/***Paginacija*/
DIV.page_numbers {
  color: #666666;
  font-size: 13px;
}

.page_filter {
  background: #F8F8F8;
  padding: 1px 10px 6px;
  margin-top: -5px;
  margin-bottom: 20px;
}

.page_filter HR {
  background-image: url(/img/crta_rezultati.png);
  background-repeat: repeat-x;
  border: none;
  height: 2px;
  margin: 3px 0;
}

.page_filter .title {
  font-weight: bold;
  color: #11598C;
  font-size: 13px;
  padding-top: 1px;
  margin-top: 5px;
}

.page_filter .filter_title {
  font-size: 11px;
  padding-left: 8px;
  color: #555555;
}

.page_filter > UL {
  margin: 6px 0 4px;
  list-style: none;
  padding-left: 0px;
}

.page_filter > UL > LI {
  font-size: 11px;
  vertical-align: top;
  display: inline-block;
  width: 138px;
  margin-right: 18px;
}

.page_filter UL UL {
  list-style: none;
  padding-left: 0;
  margin-top: 0px;
  margin-bottom: 4px;
  padding-bottom: 0px;
}

.page_filter UL UL LI {
  background-color: #EFEFEF;
  border: 1px solid #D0D0D0;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  padding-left: 7px;
  padding-top: 2px;
  font-size: 12px;
  position: relative;
  margin-bottom: 3px;
  padding-bottom: 2px;
}

.page_filter .filter_clear_link {
  display: block;
  position: absolute;
  right: 6px;
  top: 6px;
  background-image: url(/static/img/x.png);
  background-repeat: no-repeat;
  width: 9px;
  height: 9px;
}

.page_filter .lower_part {
  margin-top: 7px;
}

.page_filter .lower_part .no_results {
  color: #B31E1F;
  font-weight: bold;
  font-size: 13px;
}

.page_filter .paginacija {
  float: right;
  padding-top: 0px;
  width: 139px;
  text-align: center;
}

.page_filter .paginacija .previous_page {
  background-image: url(/static/img/nazaj_active.png);
  display: block;
  width: 20px;
  height: 20px;
  float: left;
  margin-right: 8px;
  margin-top: -2px;
}

.page_filter .paginacija .previous_page.inactive {
  background-image: url(/static/img/nazaj_inactive.png);
}

.page_filter .paginacija .next_page {
  background-image: url(/static/img/naprej_active.png);
  display: block;
  width: 20px;
  height: 20px;
  float: right;
  margin-left: 8px;
  margin-top: -2px;
}

.page_filter .paginacija .next_page.inactive {
  background-image: url(/static/img/naprej_inactive.png);
}

/*[clearfix]*/
DIV.pagination {
  text-align: center;
  margin-bottom: 0px;
  width: 563px;
  color: #666666;
  margin-top: 33px;
}

/*[clearfix]*/
DIV.pagination UL {
  list-style: none;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  padding-left: 0px;
  margin-top: 12px;
  padding-top: 0;
  margin-bottom: 0px;
}

DIV.pagination LI {
  /*[disabled]float:left;*/
  display: inline-block;
  padding-left: 9px;
  padding-right: 10px;
  height: 21px;
  padding-top: 4px;
  padding: 4px 10px 2px 11px;
  padding-bottom: 2px;
}

DIV.pagination LI.active {
  background-color: #11598C;
  color: #FFFFFF;
}

/***Slider*/
.slider_cont {
  width: 448px;
  height: 20px;
  position: relative;
  /*[disabled]border:1px solid #000000;*/
}


/***Vizi loader***/
.viziloader {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 10000;
}

.viziloader .middlecont {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -100px;
  margin-top: -25px;
  height: 50px;
  width: 200px;
  text-align: center;
  background-color: #444444;
  color: #FFFFFF;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

.viziloader .middlecont .loader {
  background-image: url(/static/img/ajax-loader-full-page.gif);
  width: 32px;
  height: 32px;
  position: absolute;
  margin-top: -16px;
  margin-left: -16px;
  top: 50%;
  left: 50%;
}

.viziloader .middlecont .loading_text {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 15px;
  font-family: $fontFamilyStandard;
  font-size: 14px;
  font-weight: bold;
}

.viziloader_image_preload {
  background-image: url("/static/img/ajax-loader-full-page.gif");
}

.galerija-cont {
  min-height: 75px;
}

.galerija-cont .loader {
  text-align: center;
  padding-top: 70px;
  color: #888888;
}

UL.galerije_sklopi {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 589px;
  padding-top: 0;
}

UL.galerije_sklopi LI {
  float: left;
  margin-right: 18px;
  margin-top: 0px;
  width: 273px;
}

UL.galerije_sklopi .naslov_galerije {
  font-size: 13px;
  font-weight: bold;
  background-color: #555555;
  color: #FFFFFF;
  padding: 5px;
  margin-top: 2px;
  position: relative;
}

UL.galerije_sklopi .naslov_galerije .arrows {
  display: block;
  position: absolute;
  right: 8px;
  top: 4px;
}

UL.galerije_sklopi .naslov_galerije A {
  text-decoration: none;
  color: #FFFFFF;
}

UL.galerija_slik {
  list-style: none;
  padding: 0px;
  margin: 0px;
  width: 590px;
  height: 100px;
}

UL.galerija_slik LI {
  float: left;
  margin-right: 8px;
  margin-bottom: 8px;
  margin-left: 0;
  margin-top: 0;
  list-style: none;
  padding-left: 0px;
}

.galerija_big_image {
  text-align: center;
}

.oddaj_narocilo_btn {
  margin-top: 24px;
  background: url(/static/img/oddaj_narocilo.png);
  border: 0 none;
  height: 34px;
  text-indent: -9999px;
  width: 140px;
  background-repeat: no-repeat;
  float: right;
  outline: none;
}

.nadaljuj_na_prijavo_btn {
  margin-top: 24px;
  background: url(/static/img/nadaljuj_na_prijavo.png);
  border: 0 none;
  height: 36px;
  text-indent: -9999px;
  width: 172px;
  background-repeat: no-repeat;
  float: right;
  outline: none;
}

.nadaljuj_na_prijavo_btn_de_at {
  background: url(/static/img/de_at/weiter-login-button.png);
  width: 164px;
  height: 34px;
  /*
  &:active{
    background-position: -166px;
  }
  */
}

.nadaljuj_na_prijavo_btn_hr {
  background: url(/static/img/hr/nastavi-na-prijavo.png);
  width: 164px;
  height: 34px;
}

UL.galerija_tab {
  list-style: none;
  padding-left: 0px;
  width: 548px;
}

UL.galerija_tab LI {
  float: left;
  margin: 8px 8px 0px 0px;
  height: 65px;
  width: 100px;
}

.prdktEkspandiranaPlocevina_narocilo {
  .produkt_vnos {
    height: 37px;

    .debelina {
      height: 30px;
      font-size: 11px;
      padding-top: 3px;
      width: 226px;
    }
  }

  .cena_kos {
    padding-top: 10px;
    width: 120px;
  }

  .quantity {
    padding-top: 10px;
    width: 140px;
  }

  .cena_skupaj {
    padding-top: 10px;
    width: 164px;
    text-align: center;

    .st_kosov {
      color: #a0a0a0;
    }
  }

  .tabela_ekspandirana .vkosarico {
    padding-top: 5px;
    width: 27px;
  }
}

.tabela_ekspandirana .produkt_vnos .po-narocilu-notice {
  width: 300px;
  padding-top: 11px;
  font-weight: bold;
}

.tabela_plocevinaste .produkt_vnos .po-narocilu-notice {
  width: 298px;
  text-align: center;
  height: 21px;
  background-color: #F5F5F5;
}

.tabela_mreze {
  .debelina_zice {
    width: 225px;
  }

  .cena_kos {
    width: 120px;
  }

  .quantity {
    width: 142px;
  }

  .cena_skupaj {
    width: 164px;
    text-align: center;

    .st_kosov {
      color: #a0a0a0;
    }
  }

  .vkosarico {
    padding-top: 2px;
    width: 27px;
  }
}

.prdktPlocevinasteResetke_narocilo .produkt_top {
  position: relative;
  margin-bottom: 2px;
}

.prdktPlocevinasteResetke_narocilo .produkt_top DIV {
  float: left;
  height: 125px;
  margin-right: 1px;
}

.prdktPlocevinasteResetke_narocilo .produkt_top .skica {
  width: 144px;
}

.prdktPlocevinasteResetke_narocilo .produkt_top .slika_produkt {
  width: 168px;
  border: 1px solid #EFEFEF;
}

.prdktPlocevinasteResetke_narocilo .produkt_top .opis {
  width: 279px;
  padding-right: 5px;
  border: 1px solid #EFEFEF;
  color: #444444;
  font-size: 11px;
}

.prdktPlocevinasteResetke_narocilo .produkt_top UL {
  padding-left: 27px;
  list-style: square;
  padding-top: 5px;
}

.prdktPlocevinasteResetke_narocilo .produkt_top LI {
  margin-bottom: 0px;
}

/*** Pločevinaste rešetke tabela ***/

UL.tabela_plocevinaste {
  LI .debelina {
    width: 58px;
    text-align: center;
  }

  LI .dolzina {
    width: 73px;
    text-align: center;
  }

  LI .sirina {
    width: 76px;
    text-align: center;
  }

  LI .visina {
    width: 60px;
    text-align: center;
  }

  LI .quantity {
    width: 80px;
    margin-left: 25px;
    text-align: center;
    font-size: 0.9em;
  }

  LI > .cena_skupaj {
    width: 160px;
    text-align: center;

    .st_kosov {
      color: #a0a0a0;
    }

    STRONG {
      /*[disabled]color:#11598C;*/
    }
  }

  LI > .vkosarico {
    width: 27px;
    padding-top: 2px;
  }

}

/*** Okovje ***/
.prdktOkovje_narocilo {
  width: 566px;
}

.produkt_sklop.prdktOkovje_narocilo H2 {
  font-size: 17px;
  width: 470px;
  margin-bottom: 8px;
}

.vodilo_opis {
  position: relative;
  margin-bottom: 0px;
}

.vodilo_opis .slike {
  margin-right: -5px;
}

.vodilo_opis .thumbs {
  margin-bottom: -8px;
}

.vodilo_opis .slike .slikabig {
  border: 1px solid #ECECEC;
  width: 370px;
  float: left;
  margin-right: 8px;
}

.vodilo_opis .slike .slikabig_komplet {
  width: 565px;
  background-color: #F7F7F7;
}

.vodilo_opis .slike .slika {
  float: left;
  margin-right: 2px;
  width: 186px;
  height: 150px;
  border: 1px solid #ECECEC;
  margin-bottom: 8px;
}

.vodilo_opis .slike .slika:last-child {
  margin-bottom: 0px;
}

.tabela_okovje {
  margin-top: 16px;
  margin-bottom: 12px;
  border: 0px dotted #D2D2D2;
  border-collapse: separate;
}

.tabela_okovje TR {
  padding-bottom: 0px;
  font-size: 11px;
}

.tabela_okovje TD {
  text-align: center;
  padding-top: 7px;
  padding-bottom: 4px;
  border-bottom: 1px dotted #D2D2D2;
  border-right: 1px dotted #CCCCCC;
}

.tabela_okovje TD:last-child {
  border-right: 0;
}

.tabela_okovje TD.cena .skupaj {
  font-weight: bold;
}

.tabela_okovje .header_1 {
  background-color: #5C5C5C;
  border-top: 0 solid #000000;
  margin-top: 12px;
  color: #FFFFFF;
}

.tabela_okovje .header_1 TD {
  border-right: 1px solid #777777;
  border-bottom: 0;
}

.tabela_okovje .header_1 TD:last-child {
  border-right: 0;
}

.tabela_okovje .header_2 {
  background-color: #EEEEEE;
}

.tabela_okovje .header_2 TD {
  border-right: 1px solid #DDDDDD;
  padding-top: 0px;
  padding-bottom: 0px;
}

.tabela_okovje .header_2 TD:last-child {
  border-right: 0;
}

.tabela_okovje .artikel {
  text-align: left;
  font-weight: bold;
  padding-left: 5px;
  padding-right: 4px;
}

.tabela_okovje TD.cena {
  width: 195px;
}

.tabela_okovje .naroci {
  padding-top: 1px;
  padding-bottom: 1px;
}

/*** Product list universal ***/
.grid3InRow {
  width: 105%;
}

.productItem3InRow {
  width: 164px;
  /*total width: 180px*/
  height: 297px;
  /*total height: 280px*/
  border: 1px solid #D6D6D6;
  font-size: 11px;
  color: #1F1F1F;
  float: left;
  margin-right: 10px;
  margin-bottom: 22px;
  padding: 0 8px;
}

.productItem3InRow .image {
  //background-color: #EEEEEE;
  position: relative;
  top: 0;
  left: -8px;
  width: 180px;
  height: 125px;
}

.productItem3InRow H2 {
  font-size: 13px;
  font-weight: bold;
  line-height: 16px;
  margin: 0px 0 3px;
  padding: 0;
}

.productItem3InRow H2 A {
  text-decoration: none;
}

.productItem3InRow H2 A:hover {
  text-decoration: underline;
}

.productItem3InRow .description {
  height: 101px;
  color: #666666;
}

.productItem3InRow .description P {
  margin: 0;
}

.productItem3InRow .price {
  font-size: 10px;
  padding-top: 7px;
  height: 21px;
  position: relative;
  border-style: dotted;
  border-width: 1px 0;
  border-color: #DEDEDE;
  margin-top: 2px;
  margin-bottom: 8px;
}

.productItem3InRow .price STRONG {
  text-align: right;
  font-size: 16px;
  font-weight: bold;
  color: #DAA506;
  position: absolute;
  top: 3px;
  right: 0;
}

.productItem3InRow .productFooter {
  position: relative;
}

.productItem3InRow A.descriptionLink {
  background-color: #7F7F7F;
  color: #FFFFFF;
  width: 41px;
  padding: 5px 0 4px;
  display: inline-block;
  /*[disabled]font-weight:bold;*/
  font-size: 11px;
  text-align: center;
  border-radius: 3px;
  text-decoration: none;
}

.productItem3InRow A.descriptionLink:hover {
  background-color: #555555;
}

.productItem3InRow .cartCtrls {
  position: absolute;
  right: 33px;
  top: 0;
  font-size: 11px;
}

.productItem3InRow .cartCtrls INPUT {
  width: 25px;
  padding: 4px;
  font-size: 14px;
  line-height: 14px;
  margin-left: 3px;
  text-align: center;
  border: 1px solid #CFCFCF;
}

.productItem3InRow .orderLink {
  background-image: url(/static/img/cart_small.png);
  text-indent: -9999px;
  display: block;
  float: right;
  width: 28px;
  height: 26px;
}

/*** Tabela GFK rešetke ***/
.prdktGFK_narocilo {
  .produkt_vnos {
    height: 37px;

    .debelina {
      height: 30px;
      font-size: 11px;
      padding-top: 3px;
      width: 224px;
    }
  }

  .cena_kos {
    padding-top: 10px;
    width: 135px;
  }

  .quantity {
    width: 100px;
    padding-top: 10px;
  }

  .cena_skupaj {
    padding-top: 10px;
    width: 174px;
    text-align: center;
  }

  .tabela_gfk .vkosarico {
    padding-top: 5px;
    width: 27px;
  }
}

/*** Lestveni profili ***/
.prdktLestveniProfil_narocilo .produkt_top {
  position: relative;
  margin-bottom: 2px;

  DIV {
    float: left;
    height: 127px;
    margin-right: 1px;
  }

  .skica {
    width: 163px;
  }

  .slika_produkt {
    width: 210px;
    border: 1px solid #EFEFEF;
  }

  .opis {
    width: 185px;
    height: 117px;
    padding: 10px 0px 0px;
    border: 1px solid #EFEFEF;
    color: #444444;
    font-size: 11px;
  }

  UL {
    padding-left: 27px;
  }

  LI {
    margin-bottom: 0px;
  }
}

/*** Tabela lestveni profili ***/
UL.tabela_lestveni_profil {
  LI > DIV.material {
    width: 215px;
  }

  LI > .cena_kos {
    width: 132px;
  }

  LI > .quantity {
    width: 90px;
  }

  LI > .cena_skupaj {
    width: 194px;
    text-align: center;
  }

  LI > .cena_skupaj STRONG {
    /*[disabled]color:#11598C;*/
  }

  LI > .vkosarico {
    width: 27px;
    padding-top: 2px;
  }

}


DIV.infobox {
  box-sizing: border-box;
  border: 2px solid #eeeeee;
  padding: 18px 10px 18px 60px;
  background: url(/static/img/icons/info.png) no-repeat 20px 50%;
  background-image: url(/static/img/icons/info.svg);
  margin: 20px 0;
}

DIV.infobox--check-also {
  font-size: 17px;
  font-weight: bold;

  A {
    text-decoration: none;
    border-bottom: 1px dotted #11598C;
  }
}

/***
*** Areas
***/
@import "areas/content3columns/_content3columns";
@import "areas/photowithborder/_style";
@import "areas/accordion/_style";

@import "_katalogForm";

/***
*** Plugins
***/
@import "lib/_viziNumberField";
@import "povprasevanje";
@import "narocilo";

.corten-sheet-metal-table, .corten-pipes-table {
  width: 80%;
}

.corten-sheet-metal-column {
  width: 25%;
  text-align: center;
}